import { del, get, post, put } from 'helpers/api_helper';
import {
  danger,
  darkBorder,
  darkGrayBg,
  lightBackground,
  lightBackgroundBlue,
  lightText,
  popUpBackground,
  primary,
} from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import ScheduleCall from '../molecules/scheduleCall';
import { IReportData } from 'library/interfaces/crdInterfaces';
import {
  getDataByIndexIndexedDb,
  IIndexedDbIncomingCallData,
  TIndexedDbCallData,
} from 'library/indexedDb/indexedDB';
import { Phone } from 'library/phone/phone';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { updateDialedNumberAction } from 'store/actions';
import TagsAndEditForm from '../molecules/tagsAndEditForm';

export type TScheduleCallState =
  | 'Llamada agendada'
  | 'Contactado'
  | 'Encolada'
  | 'No se pudo contactar al cliente';
export interface IScheduleCall {
  contact: string;
  contact_datetime: string;
  e64_data: string;
  agent: string;
  id: string;
  campaign_id: string;
  call_datetime?: string;
  attempts?: string;
  state?: TScheduleCallState;
}
interface IPhoneBookProps {
  phone: Phone;
  setShowPhoneBook: (show: boolean) => void;
}
const PhoneBook = ({ setShowPhoneBook, phone }: IPhoneBookProps) => {
  const dispatch = useAppDispatch();
  const { roles, exten, pbx_url } = useAppSelector(store => store.authUserData);
  const campaigns = useAppSelector(store => store.voiceCampaigns.campaigns);
  const { users } = useAppSelector(
    store => store.cdr.reportData,
  ) as IReportData;
  const [scheduleCalls, setScheduleCalls] = useState<IScheduleCall[]>([]);

  const [historical, setHistorical] = useState<TIndexedDbCallData[]>([]);

  const [callToTag, setCallToTag] = useState<TIndexedDbCallData | null>(
    null,
  );

  // si existe esta id se esta editando
  const [scheduleCallId, setScheduleCallId] = useState('');

  const [e64Data, setE64Data] = useState<{ [key: string]: string | number}[]>([]);

  const [campaign_id, setCampaign_id] = useState('');
  const [contact, setContact] = useState('');
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [notes, setNotes] = useState('');

  const [filter, setFilter] = useState<TScheduleCallState | ''>(
    'Llamada agendada',
  );
  const [NumberFilter, setNumberFilter] = useState('');
  const [SelectedAgent, setSelectedAgent] = useState('');

  const isSupervisor = useMemo(() => {
    return roles.some(rol => rol.name === 'supervisor');
  }, [roles]);

  const scheduleCallsToRender = useMemo(() => {
    const filteredByNumber = !NumberFilter ? scheduleCalls : scheduleCalls.filter(call => call.contact.includes(NumberFilter));
    const filteredByAgent = !SelectedAgent
      ? filteredByNumber
      : filteredByNumber.filter(call => call.agent === SelectedAgent);
    if (filter === 'Llamada agendada') {
      return filteredByAgent.filter(call => call.state === filter).reverse();
    } else if (filter) {
      return filteredByAgent.filter(call => call.state === filter);
    }
    return filteredByAgent;
  }, [scheduleCalls, filter, SelectedAgent, NumberFilter]);

  // const handleFilterClick = (filterValue: TScheduleCallState | '') => {
  //   setFilterButtons(prev =>
  //     prev.map(but => {
  //       if (but.value === filterValue) {
  //         return { ...but, isSelected: true };
  //       }
  //       return { ...but, isSelected: false };
  //     }),
  //   );
  //   setFilter(filterValue);
  // };

  const handleCampaignChange = async (campaign_id: string) => {
    try {
      setCampaign_id(campaign_id);

      if (!campaign_id) return;

      const isSupervisor = roles.some(rol => rol.name === 'supervisor');

      const string = isSupervisor
        ? `/${campaign_id}`
        : `/${campaign_id}/${exten}`;

      const agenda = await get(`/pbx/campaign/schedule/call${string}`);
      setScheduleCalls(agenda);
    } catch (error) {
      console.log(error);
      setScheduleCalls([]);
      toast.error('Hubo un error recuperando las llamadas agendadas');
    }
  };

  const handleCall = (number: string, server: string) => {
    try {
      dispatch(updateDialedNumberAction(number));
      phone.call(number, server);
      setShowPhoneBook(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputNumberChange = (value: string) => {
    setContact(value);
    if (e64Data.length > 0) setE64Data([]);
    if (notes) setNotes('');
  };

  const handleCopyPhoneAndData = (call: TIndexedDbCallData) => {
    setContact(call.number);
    if (call.direction === 'outgoing') return;
    setCampaign_id(call.campaignId || '');
    setE64Data(call.e64_data || []);
  };

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton'
    ) {
      return setShowPhoneBook(false);
    }
  };

  function encodeToBase64Unicode(str: string): string {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  }
  function decodeFromBase64Unicode(base64: string): string {
    return decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );
  }

  const saveScheduleCall = async () => {
    if (!campaign_id || !contact || !date || !hour)
      return toast.error('Faltan datos para agendar la llamada');

    const userName = users.find(usr => usr.exten === exten)?.name || '';

    const notesToSave = notes
      ? [
          {
            'Notas de Agenda':
              notes +
              ` (${userName + ' ' + exten} - ${new Date().toDateString()})`,
          },
          ...e64Data,
        ]
      : e64Data;
    const newSchedule: IScheduleCall = {
      agent: exten,
      campaign_id,
      contact,
      contact_datetime: `${date} ${hour}`,
      e64_data: encodeToBase64Unicode(JSON.stringify(notesToSave)),
      id: crypto.randomUUID(),
    };
    try {
      await post('/pbx/campaign/schedule/call', newSchedule);
      setScheduleCalls(prev => [newSchedule, ...prev]);
      setContact('');
      setDate('');
      setHour('');
      setNotes('');
      setE64Data([]);
    } catch (error) {
      console.log(error);
      toast.error('Ha ocurrido un error al agendar la llamada');
    }
  };

  const editScheduleCall = async () => {
    if (!campaign_id || !contact || !date || !hour || !scheduleCallId)
      return toast.error('Faltan datos para editar la llamada');

    const userName = users.find(usr => usr.exten === exten)?.name || '';

    const notesToSave = notes
      ? [
          {
            'Notas de Agenda':
              notes +
              ` (${userName + ' ' + exten} - ${new Date().toDateString()})`,
          },
          ...e64Data,
        ]
      : e64Data;
    const newSchedule: IScheduleCall = {
      agent: exten,
      campaign_id,
      contact,
      contact_datetime: `${date} ${hour}`,
      e64_data: encodeToBase64Unicode(JSON.stringify(notesToSave)),
      id: scheduleCallId,
    };
    try {
      await put(
        `/pbx/campaign/schedule/call/${campaign_id}/${scheduleCallId}`,
        newSchedule,
      );
      setScheduleCalls(prev =>
        prev.map(cal => {
          if (cal.id === scheduleCallId) return newSchedule;
          return cal;
        }),
      );
      setContact('');
      setDate('');
      setHour('');
      setNotes('');
      setE64Data([]);
      setScheduleCallId('');
    } catch (error) {
      console.log(error);
      toast.error('Ha ocurrido un error al agendar la llamada');
    }
  };

  const deleteScheduleCall = async (
    campaignId: string,
    scheduleCallId: string,
  ) => {
    try {
      setScheduleCalls(prev => prev.filter(sch => sch.id !== scheduleCallId));
      await del(`/pbx/campaign/schedule/call/${campaignId}/${scheduleCallId}`);
      setContact('');
      setDate('');
      setHour('');
      setNotes('');
      setE64Data([]);
      setScheduleCallId('');
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditButtonClick = (scheduleCall: IScheduleCall) => {
    const [date, hour] = scheduleCall.contact_datetime.split(' ');
    const e64Decoded = JSON.parse(
      decodeFromBase64Unicode(scheduleCall.e64_data),
    );

    setCampaign_id(scheduleCall.campaign_id);
    setContact(scheduleCall.contact);
    setDate(date);
    setHour(hour);
    setE64Data(e64Decoded);
    setScheduleCallId(scheduleCall.id);
  };

  const handleTagCallButtonClick = (incomingCall: TIndexedDbCallData) => {
      return setCallToTag(incomingCall);
  };

  useEffect(() => {
    getDataByIndexIndexedDb(exten).then(res => {
      setHistorical(res);
      if (res.length === 0) return;
      const [lastCall] = res;
      setContact(lastCall.number);

      if (lastCall.direction === 'outgoing') return;

      setCampaign_id(lastCall.campaignId || '');

      if (!lastCall.e64_data || lastCall.e64_data.length === 0) return;

      setE64Data(lastCall.e64_data);
    });
  }, []);

  const hide = [
    "035b8d56-ec92-406d-abc6-86388651a1d4",
    "e0bbf26e-6321-438e-af4b-37f452fedded",
    "041fa8a5-2d6f-4520-8c30-e19d72366ff1",
    "d7f12c03-79ba-487e-b4d3-e209c331e80a",
    "d869299e-538c-4753-84a8-49c9837757a4",
    "9142d16d-c20c-4fb3-b3b4-df6503c9c029",
    "ea16d43d-803b-4305-a4b1-f4e36ac39e4a",
    
    "f477051b-3277-4f98-9900-8f2e38d59d9e",
    "1d61d8ae-54ec-4ea1-93e7-e0b957f89197",
    "6e64017f-2edb-4a6a-ad81-4beeaf29f30c",
    "f18d7f50-9571-44cd-a2b8-668762629b94",
    "f534d8f2-0814-48c9-97d4-a22c1cbe5920",
    "b726e92e-214d-4155-adc5-8df32d3633d6",
    "e0e1d9f3-4952-428f-a4c7-5de58e146f0f",
    "1d460bca-6ca3-4ccf-83fb-d8858dd6a716",
    "4fde5123-fbf3-4221-9fb2-3b1bfd40cc66",
    "8e3c20b3-ef59-4265-857d-7f1d136bfa47",
    "cb160360-1c56-4cd8-9bb3-5ef3763b1d92",
    "9cd81bf4-a60a-4e4d-94d3-8ad952e4583b",
    "a6865be2-9492-411a-af2d-24080dc9ffa5",
    "94698f7a-594c-4374-ae77-7c2b219bbca2",
    "61ef302f-e1a8-4ea3-8e5a-c44fcff4269b",
    "85396111-88fe-4e8e-9707-7fd7f0ca3a16",
    "830bc441-237a-43c2-b807-70a110213fd3",
    "5a83930a-28b6-41bd-b3ed-83c37d40c289",
    "eb5721de-f6f8-42bd-9b1d-3d1496886caf",
    "04c0113d-508d-429a-88ff-18bedff0177f",
    "65935c5a-3168-4f3b-bb60-4de1a364f9d2",
    "e41bc7b3-b7ae-40d0-a9ba-525d51a911dc",
    "9e952c72-2bc3-4706-b241-5faeeddea563",
    "cfa22198-9467-4ebc-941e-d9984677c28d",
    "44ea605a-8cfe-433d-937c-0ea62d4273ac",
    "71d5e27d-fb39-4c33-974f-8a12cf7b3fe4",
    "476f5e7d-4cec-4cad-a219-89597960ef5a",
    "5af0afec-f9e4-4911-ad92-e60357aa7103",
    "6fb264f3-7aec-4c44-8ffb-10d68068fedf",
    "fdd693a2-de21-4320-8fcc-63734731e337",
    "f8542182-374e-406b-a51d-13afa08aaa51",
    "65f1eb24-e588-4d69-90f4-857cfa28a05b",
    "dc260409-6246-4b73-844a-5c0effd561a2",
    "e58ccf2d-99ab-4406-a4ba-0154a8d84906",
    "b7d614b1-0896-4c21-8388-574b8a8f48c2",
    "f1c9c5d3-4b5c-4bf5-b889-672c3db18b42",
    "082eb873-941b-42d9-9e46-a222f5a77b2f",
    "bdfc0a29-cd71-40bf-a048-e5e8aebf1a23",
    "69ff22d1-7782-4acb-811f-e992ec782d77",
    "bd54ad95-fe81-47c1-99ff-ec98371a30b8",
    "28715315-f1df-4bb0-b089-118ba6c93697",
    "f4821f18-452f-478f-bd00-2a438eb07a78"
]

  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton">X</Exit>
        <HistoryCont>
          {historical.map((call, index) => {
            return (
              <HistCallCont key={index}>
                <CallNumber>
                  <Direction isIncoming={call.direction === 'incomming'}>
                    <i
                      className={`bx bxs-${
                        call.direction === 'incomming' ? 'down' : 'up'
                      }-arrow`}
                    />
                  </Direction>
                  {call.number}
                </CallNumber>
                <IconsCont>
                  <PhoneCall onClick={() => handleCall(call.number, pbx_url)}>
                    <i className="bx bx-phone-call" />
                  </PhoneCall>
                  <PhoneCall onClick={() => handleCopyPhoneAndData(call)}>
                    <i className="bx bx-copy-alt" />
                  </PhoneCall>
                  <PhoneCall onClick={() => handleTagCallButtonClick(call)}>
                    <i className="bx bx-purchase-tag-alt" />
                  </PhoneCall>
                </IconsCont>
              </HistCallCont>
            );
          })}
        </HistoryCont>
        <Agenda>
          {callToTag && (
            <TagsAndEditForm call={callToTag} exit={() => setCallToTag(null)} />
          )}
          <Form>
            <InputCont>
              <Label>Campaña</Label>
              <CampaignSelect
                value={campaign_id}
                onChange={e => handleCampaignChange(e.target.value)}
              >
                <option value="">Selecciona una campaña</option>
                {campaigns.map(camp => {
                  if(hide.includes(camp.id)) return
                  return (
                    <option key={camp.id} value={camp.id}>
                      {camp.name}
                    </option>
                  );
                })}
              </CampaignSelect>
            </InputCont>
            <InputCont>
              <Label>Contacto</Label>
              <Input
                type="text"
                placeholder="Numero"
                value={contact}
                onChange={e => handleInputNumberChange(e.target.value)}
              />
            </InputCont>
            <InputCont>
              <Label>Día</Label>
              <Input
                type="date"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </InputCont>
            <InputCont>
              <Label>Hora</Label>
              <Input
                type="time"
                value={hour}
                onChange={e => setHour(e.target.value)}
              />
            </InputCont>
            <Notes
              placeholder={` Notas / Información extra ${
                e64Data.length > 0 ? ' ---  Datos de campaña copiados' : ''
              }`}
              value={notes}
              onChange={e => setNotes(e.target.value)}
            />
            {!scheduleCallId && (
              <Button onClick={() => saveScheduleCall()}>Agendar</Button>
            )}
            {scheduleCallId && (
              <Button onClick={() => editScheduleCall()}>Editar</Button>
            )}
          </Form>
          <E64DataCont>
            {e64Data.map((item, index) => {
              const key = Object.keys(item)[0];
              return (
                <Data key={index}>
                  <div>{key}:</div> <b>{item[key]}</b>{' '}
                </Data>
              );
            })}
          </E64DataCont>
          <ScheduleCallsBlueCont>
            <FiltersCont>
              <Select
                value={filter}
                onChange={e =>
                  setFilter(e.target.value as TScheduleCallState | '')
                }
              >
                <option value="">Selecciona un filtro</option>
                {SCHEDULE_CALLS_FILTERS.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </Select>
              <Input
                value={NumberFilter}
                onChange={e => setNumberFilter(e.target.value)}
                placeholder="Filtra por número de contacto"
              />
              {isSupervisor && (
                <Select
                  value={SelectedAgent}
                  onChange={e => setSelectedAgent(e.target.value)}
                >
                  <option value="">Todos los agentes</option>
                  {users.map((usr, index) => {
                    return (
                      <option key={index} value={usr.exten}>
                        {usr.name}
                      </option>
                    );
                  })}
                </Select>
              )}
            </FiltersCont>
            <ScheduleCallsCont>
              {scheduleCallsToRender.map((cal, index) => {
                const data = JSON.parse(decodeFromBase64Unicode(cal.e64_data));
                return (
                  <ScheduleCall
                    call={cal}
                    users={users}
                    e64Data={data}
                    deleteScheduleCall={deleteScheduleCall}
                    handleEditButtonClick={handleEditButtonClick}
                    key={index}
                  />
                );
              })}
            </ScheduleCallsCont>
          </ScheduleCallsBlueCont>
        </Agenda>
      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.div`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 100%;
  max-width: 1030px;
  height: 100%;
  max-height: 80vh;
  color: black;
  padding: 30px 20px 20px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-gap: 10px;
  overflow: hidden;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HistoryCont = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const HistCallCont = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${lightBackground};
`;
const IconsCont = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const CallNumber = styled.div``;
const Direction = styled.div<IDirection>`
  line-height: 0;
  display: inline-block;
  margin-right: 5px;
  transform: translateY(1px);
  color: ${props => (props.isIncoming ? primary : danger)};
`;
interface IDirection {
  isIncoming: boolean;
}
const PhoneCall = styled.div`
  cursor: pointer;
  color: #fff;
  /* aspect-ratio: 1; */
  padding: 5px 10px;
  border-radius: 50px;
  line-height: 0;
  background-color: ${darkBorder};

  &:hover {
    background-color: ${primary};
  }
`;

const Agenda = styled.div`
  position: relative;
`;
const Form = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 10px;
  /* place-items: center; */
`;
const CampaignSelect = styled.select`
  width: 100%;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
  background-color: transparent;
`;
const InputCont = styled.div`
  /* border: 1px solid; */
`;
const Input = styled.input`
  width: 100%;
  max-width: 207px;
  height: 29px;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
`;
const Label = styled.label`
  margin: 0;
  padding: 0;
  /* line-height: 0; */
  font-size: 0.7rem;
  /* font-weight: 500; */
  color: ${darkGrayBg};
`;
const Notes = styled.textarea`
  grid-column: 1 / 4;
  resize: none;
  border-radius: 5px;
  border: 1px solid ${primary};
`;
const Button = styled.div`
  width: 100%;
  height: 45px;
  text-align: center;
  line-height: 43px;
  /* padding: 5px 0; */
  border-radius: 5px;
  font-size: 1rem;
  align-self: center;
  justify-self: center;
  color: white;
  cursor: pointer;
  background-color: ${primary};
`;

const E64DataCont = styled.div`
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  height: 100px;
  background-color: ${lightBackgroundBlue};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;

const Data = styled.div`
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 100px 1fr;
`;

const ScheduleCallsBlueCont = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
  position: relative;
`;
const ScheduleCallsCont = styled.div`
  margin-top: 10px;
  height: 445px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;

const FiltersCont = styled.div`
  display: flex;
  gap: 20px;
`;
const Select = styled.select`
  max-width: 160px;
  width: 100%;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
  background-color: #fff;
`;

export default PhoneBook;

const SCHEDULE_CALLS_FILTERS: {
  label: string;
  value: TScheduleCallState | '';
}[] = [
  { label: 'Próximas', value: 'Llamada agendada' },
  { label: 'Contactado', value: 'Contactado' },
  { label: 'Encolada', value: 'Encolada' },
  {
    label: 'No se pudo contactar',
    value: 'No se pudo contactar al cliente',
  },
  { label: 'Todas', value: '' },
];
